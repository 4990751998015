import React from 'react';
import SignupForm from './SignupForm';


function App() {
  return (
    <div className="flex h-screen bg-gray-100">
      <div className="m-auto">
        <SignupForm />
      </div>
    </div>
  );
}

export default App;
